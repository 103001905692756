<template>
  <div class="landing">
    <h1>var {{titleDisplay}} = <span class="cursor">__</span> ;</h1>
    {{msg}}
    <div class="def" v-if="definition !== null">
        <p class="word">{{definition[0].word}}: <Play class="play" @click="play" aria-hidden="true" /><span aria-hidden="true">{{definition[0].phonetics[0].text}}</span></p>
        <!-- <p>{{definition[0].meanings}}</p> -->
        <div v-for="(meaning, index) in definition[0].meanings" :key="'def'+index"> 
            <p>{{meaning.partOfSpeech}}: </p>
            <p><b>definition:</b> <i>{{meaning.definitions[0].definition}}</i></p>
            <p><b>example:</b> <i>{{meaning.definitions[0].example}}</i></p>
        </div>
    </div>
    <div class="projects">
    <div class="projects-left">
        <h2>Projects</h2>
        <ul>
            <li> 
                <h3>Slow Band</h3>
                <a target="_blank" href="https://slowband.info">https://slowband.info</a>
                <p>simple concept site to highlight the digital divide between country and city</p>
            </li>
            <li> 
                <h3>Deepest Why</h3>
                <a target="_blank" href="https://deepestwhy.com">https://deepestwhy.com</a> 
                <p>Unifinshed web application to help someone concentrate on a subject</p>
            </li>
        </ul>
    </div>
    <div class="projects-right">
        <h2>Resources</h2>
        <ul>
            <li> 
                <h3>Public Github</h3>
                <a target="_blank" href="https://github.com/jbeetz/protocubism">https://github.com/jbeetz/protocubism</a>
                <p>documentation for different projects</p>
            </li>
        </ul>
    </div>
    </div>
    <br/>
    <div class="skills">
    <div class="skills-left">
        <h2>Technologies</h2>
        <ul>
            <li>
                Javascript, VUE JS Framework
            </li>
            <li>
                Sage X3 Development 4GL
            </li>
            <li>
                python
            </li>
            <li>
                Node JS 
            </li>
            <li>
                SQL / ORM / NoSQL
            </li>
            <li>
                graphql / hasura
            </li>
            <li>
                PowerShell VB.net
            </li>
        </ul>
    </div>
     <div class="skills-right">
        <h2>Tech Stacks / Methodlogies</h2>
        <ul>
            <li>
                AWS
            </li>
            <li>
                Github
            </li>
            <li>
                DRY Principles
            </li>
            <li>
                Serverless
            </li>
        </ul>
    </div>
    </div>
    <br/>
    <div class="history">
        <h2>Background: </h2>
        <p>learned to code in the late 80's on a <span class="COMODORE">COMODORE 64</span> that my dad brought home from work</p>
        <p>working in private industry IT Generalist role, soup to nuts</p>
    </div>
    <a href="mailto:jbeetz@varproto.com">contact</a>
  </div>
</template>

<script>
import axios from 'axios';
import { PlayCircleOutline } from 'mdue';
export default {
  name: "landing",
  components: {
      Play: PlayCircleOutline
  },
  data(){return {
      defined: null,
      title: ["inspiration", "sketch", "idea", "concept", "proto" ],
      toDefine: ["cubism", "sketch", "idea", "concept", "prototype"],
      counter: 0
  }},
  props: {
    msg: String
  },
  async mounted(){
      let max = this.title.length-1;
          let c = 0;
          let self = this;
          this.counter = await setInterval(() => {
              console.log('timer:', c, self.title[c])
              if (c < max) {  
                c = c+1;
              } else {
                  c = 0;
              }
              self.counter = c;
              self.definer(c);
          }, 15000);
          this.counter = 0;
    console.log(this.counter, "outside timer", max);
      this.definer(this.counter);
  },
  computed: {
      titleDisplay: function(){
          console.log(this.counter)
          return this.title[this.counter];
      },
      definition(){
          return this.defined
      }
  },
  methods: {
    async play(){
          console.log(this.definition[0].phonetics[0].audio);
          let audio = await new Audio(this.definition[0].phonetics[0].audio);
          audio.play();
      },
    async definer(c){
        let word = this.toDefine[c];
      let def = await axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en_US/${word}`);
      console.log(def.data)
          this.defined = def.data;
        console.log(this.defined[0].meanings[0].definitions[0].definition)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
ul
    list-style-type: none
.cursor 
    -webkit-animation: blink 2.5s infinite
@-webkit-keyframes blink
    0% {color: #fff}
    50% {color: #000}
    100% {color: #fff}
.def
    width: 60%
    text-align: left !important
    font-size: 12px
    padding-left: 20%
    min-height: 270px
.history 
    text-align: left
    padding-left: 10%
    font-size: 12px
.projects 
    display: grid
    padding-left: 10%
    padding-right: 10%
    width: 80%
    grid-template-columns: auto
    grid-template-rows: auto
    grid-template-areas: "leftcol rightcol" "leftcol rightcol" "leftcol rightcol" 
    overflow: auto
.skills 
    display: grid
    padding-left: 10%
    padding-right: 10%
    font-size: 12px
    width: 80%
    grid-template-columns: auto
    grid-template-rows: auto
    grid-template-areas: "leftcolskill rightcolskill" "leftcolskill rightcolskill" "leftcolskill rightcolskill" 
    overflow: auto
@media (max-width: 575px)
    .projects 
        display: grid
        padding-left: 10%
        padding-right: 10%
        width: 80%
        grid-template-columns: auto
        grid-template-rows: auto
        grid-template-areas: "leftcol" "rightcol"
        overflow: auto
    .skills 
        display: grid
        padding-left: 10%
        padding-right: 10%
        width: 80%
        grid-template-columns: auto
        grid-template-rows: auto
        grid-template-areas: "leftcolskill" "rightcolskill"
        overflow: auto
.projects-left
    grid-area: leftcol
    text-align: left
    font-size: 12px
.projects-right
    grid-area: rightcol
    text-align: left
    font-size: 12px
.skills-left
    grid-area: leftcolskill
    text-align: left
    font-size: 12px
.skills-right
    grid-area: rightcolskill
    text-align: left
    font-size: 12px
.word 
    font-size: 15px
    font-weight: bold
.COMODORE
    font-family: 'Press Start 2P', cursive
.play
    font-size: 30px
    color: #477B4C
    vertical-align: middle
    padding: 5px
</style>
